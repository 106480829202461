<section *ngIf="cargo">
  <div class="cargo" [ngStyle]="semaphore"
    *ngIf="cargo && ((typeList === 'loadsInNegotiation' || typeList === 'cargoRequestNegotiation') && isFixedCargo()) || (typeList !== 'loadsInNegotiation' && typeList !== 'cargoRequestNegotiation')"
    [ngClass]="{'item-list-selected': cargo?.checked, 'border-intermitente-card': showTransportedValueSemaphore(), 'background-sleep': cargo?.stateTracking === 'Pause' && route?.snapshot?.url[0]?.path === 'loadingRoutes'}">

    <div *ngIf="isLoadingRoutesTab" class="lateral-info text-center">
      <div [ngStyle]="semaphoreBody" class="last-track text-bold">
        Último seguimiento: <br>
        {{trackingTime[0].toUpperCase() + trackingTime.slice(1)}}
      </div>
      <br>
      <div class="row semaphore-states">
        <div class="col-md-12 col-sm-6 text-center">
          <span>Estado del servicio</span><br>
          <img *ngIf="cargo?.state == cargoStateEnum.START_SERVICE"
            [src]="'../../../../assets/svg/icons/'+incident.asset" width="32" alt=""><br>
          <b>{{incident.name}}</b>
        </div>
        <div class="col-md-12 col-sm-6 text-center br-on-full">
          <span>Perfil de riesgo</span><br>
          <i class="circle" [ngStyle]="trackingRiskProfileBg">&nbsp;</i><br><b>{{trackingRiskProfile}}</b>
        </div>
      </div>
    </div>

    <div class="d-table-cell w-100">
      <div class="d-flex flex-row flex-wrap">
        <app-bell-alert class="mr-3" *ngIf="activeBell&&showBells" [cargo]="cargo">
        </app-bell-alert>
        <div class="d-flex flex-row flex-wrap"
          *ngIf="isLoadingRoutesTab && cargo?.state === cargoStateEnum.START_SERVICE">
          <div class="alerts-expiration-date" *ngIf="cargo?.containerExpirationDate">
            <span class="badge badge-danger mr-2" [ngClass]="{'badge-danger': diffInDaysContainerExp <= expirationDateContainerEnum.RED_WARNING,
                          'badge-warning': diffInDaysContainerExp === expirationDateContainerEnum.YELLOW_WARNING,
                          'badge-success': diffInDaysContainerExp >= expirationDateContainerEnum.GREEN_WARNING }">
              Contenedor: {{ diffInDaysContainerExp }} día<span *ngIf="diffInDaysContainerExp !== 1">s</span>
            </span>
          </div>
          <div class="alerts-expiration-date-DTA"
            *ngIf="cargo?.cargoModel?.tripType?.date && (cargo?.cargoModel?.tripType?.regimeType === 'DUTA' || cargo?.cargoModel?.tripType?.regimeType === 'OTM')">
            <span class="badge badge-danger"
              *ngIf="expirationDaysDTAToDownload <= ExpirationDateDTAEnum.RED_WARNING && expirationDaysDTAToDownload !== 0">Límite
              de descargue:
              {{ expirationDaysDTAToDownload }} día<span *ngIf="expirationDaysDTAToDownload !== 1">s</span></span>
            <span class="badge badge-danger"
              *ngIf="expirationDaysDTAToDownload <= ExpirationDateDTAEnum.RED_WARNING && expirationDaysDTAToDownload === 0">Límite
              de descargue: Hoy</span>
            <span class="badge badge-warning"
              *ngIf="expirationDaysDTAToDownload === ExpirationDateDTAEnum.YELLOW_WARNING">Límite de descargue: {{
              expirationDaysDTAToDownload }} día<span *ngIf="expirationDaysDTAToDownload !== 1">s</span></span>
            <span class="badge badge-success"
              *ngIf="expirationDaysDTAToDownload >= ExpirationDateDTAEnum.GREEN_WARNING">Límite de descargue:
              {{
              expirationDaysDTAToDownload }} día<span *ngIf="expirationDaysDTAToDownload !== 1">s</span></span>
          </div>
        </div>
        <div class="alerts-expiration-date" *ngIf="canAlertBill">
          <span class="badge badge-danger mr-2" [ngClass]="{'badge-dark': diffInDaysBillExp <= expirationBillEnum.BLACK_WARNING,
                          'badge-danger': diffInDaysBillExp === expirationBillEnum.RED_WARNING,
                          'badge-warning': diffInDaysBillExp === expirationBillEnum.YELLOW_WARNING,
                          'badge-success': diffInDaysBillExp >= expirationBillEnum.GREEN_WARNING }">
            Factura: {{ diffInDaysBillExp }} día<span *ngIf="diffInDaysBillExp !== 1">s</span>
          </span>
        </div>
      </div>

      <div class="d-flex flex-row flex-wrap align-items-center alerts-expiration-date-DTA ml-2"
        *ngIf="cargo && [cargoStateEnum.ACCEPTED, cargoStateEnum.CREATED].includes(cargo?.state) && cargo?.cargoModel?.tripType?.date && (cargo?.cargoModel?.tripType?.regimeType === 'DUTA' || cargo?.cargoModel?.tripType?.regimeType === 'OTM') ">
        <span class="badge badge-danger mr-2"
          *ngIf="expirationDaysDTAToLoad <= ExpirationDateDTAEnum.RED_WARNING && expirationDaysDTAToLoad !== 0">Límite
          de descargue:
          {{ expirationDaysDTAToLoad }} día<span *ngIf="expirationDaysDTAToLoad !== 1">s</span></span>
        <span class="badge badge-danger"
          *ngIf="expirationDaysDTAToLoad <= ExpirationDateDTAEnum.RED_WARNING && expirationDaysDTAToLoad === 0">Límite
          de descargue: Hoy</span>
        <span class="badge badge-warning mr-2"
          *ngIf="expirationDaysDTAToLoad === ExpirationDateDTAEnum.YELLOW_WARNING">Límite de descargue: {{
          expirationDaysDTAToLoad }} día<span *ngIf="expirationDaysDTAToLoad !== 1">s</span></span>
        <span class="badge badge-success" *ngIf="expirationDaysDTAToLoad >= ExpirationDateDTAEnum.GREEN_WARNING">Límite
          de descargue:
          {{
          expirationDaysDTAToLoad }} día<span *ngIf="expirationDaysDTAToLoad !== 1">s</span></span>
      </div>

      <div>
        <button mat-raised-button class="primary" color="primary" (click)="selectDriver(cargo)"
          *ngIf="!cargo.assignedDriver && !cargo.licensePlate">
          <div class="container-number">
            {{visualNumberOfOffers}}
          </div>
          <span><i class="fas fa-truck"></i>Asignar vehículo</span>
        </button>
      </div>

      <div class="row cargo__header relative">
        <div class="absolute absolute--right-top cargo__consecutive">
          <div *ngIf="cargo?.state === cargoStateEnum.CREATED && showTurnVehicles" class="text-turn d-none d-md-block"
            [ngClass]="{
              'color-primary': filteredCargosTurns && filteredCargosTurns?.matches && filteredCargosTurns?.matches?.length,
              'color-primary-dark': !filteredCargosTurns || (filteredCargosTurns && filteredCargosTurns?.matches?.length === 0),
              'cursor-pointer': filteredCargosTurns && filteredCargosTurns?.matches && filteredCargosTurns?.matches?.length
            }"
            [title]="filteredCargosTurns && filteredCargosTurns.matches && filteredCargosTurns.matches.length ? 'Ver lista de vehiculos enturnados' : ''"
            (click)="AvaliableTurnList()">
            <u>
              {{ filteredCargosTurns?.matches?.length ?
              ('(' + filteredCargosTurns?.matches?.length + ')' + ' Vehículo' +
              (filteredCargosTurns?.matches?.length > 1 ? 's' : '') +
              ' enturnado' +
              (filteredCargosTurns?.matches?.length > 1 ? 's' : '') +
              ' disponible' +
              (filteredCargosTurns?.matches?.length > 1 ? 's' : ''))
              : '(0) Vehículos enturnados disponibles'}}
            </u>
          </div>
          <div class="id-cargo-container"
            [ngClass]="{'mt-2': cargo?.state === cargoStateEnum.CREATED && filteredCargosTurns?.matches?.length}">
            <span title="Consecutivo de servicio Nº {{cargo?.consecutive}}">
              Id: {{ (cargo?.consecutive || cargo?.consecutive===0) ? (cargo?.consecutive) : '-' }}
            </span>
          </div>

        </div>
        <div class="col-lg-2 col-sm-4">
          <span class="title-purple" title="{{cargo?.dateLoad | dateFormat:'only-date'}}">
            {{cargo?.dateLoad | dateFormat:'only-date'}}
          </span>
        </div>
        <div class="col-lg-2 col-sm-4" *ngIf="cargo?.durationTime?.startDate">
          <span class="title-purple" title="{{cargo?.durationTime.startDate | dateFormat:'time'}}">
            {{cargo?.durationTime.startDate | dateFormat:'time' }}
          </span>
        </div>
        <div class="col-lg-6">
          <span class="title-purple text-break" title="{{cargo?.numberDocumentSender}}">
            N° Documento remitente: {{cargo?.numberDocumentSender}}
          </span>
        </div>
        <div class="col-12 text-turn d-block d-md-none"
          *ngIf="cargo?.state === cargoStateEnum.CREATED && showTurnVehicles" [ngClass]="{
            'color-primary': filteredCargosTurns && filteredCargosTurns?.matches && filteredCargosTurns?.matches?.length,
            'color-primary-dark': !filteredCargosTurns || (filteredCargosTurns && filteredCargosTurns?.matches?.length === 0),
            'cursor-pointer': filteredCargosTurns && filteredCargosTurns?.matches && filteredCargosTurns?.matches?.length
          }"
          [title]="filteredCargosTurns && filteredCargosTurns?.matches && filteredCargosTurns?.matches?.length ? 'Ver lista de vehiculos enturnados' : ''"
          (click)="AvaliableTurnList()">
          <u>
            {{ filteredCargosTurns?.matches?.length ?
            ('(' + filteredCargosTurns?.matches?.length + ')' + ' Vehículo' +
            (filteredCargosTurns?.matches?.length > 1 ? 's' : '') +
            ' enturnado' +
            (filteredCargosTurns?.matches?.length > 1 ? 's' : '') +
            ' disponible' +
            (filteredCargosTurns?.matches?.length > 1 ? 's' : ''))
            : '(0) Vehículos enturnados disponibles'}}
          </u>

        </div>
        <div class="col-lg-6">
          <span class="title-purple text-break" title="{{cargo?.idCompany | companyName}}">
            Operación: {{cargo?.idCompany?(cargo?.idCompany | companyName):''}}
          </span>
        </div>
      </div>

      <div class="row cargo__body">
        <!-- Origin -->
        <div class="col-lg-2 col-sm-4">
          <div class="label">
            Origen
          </div>
          <div class="text-bold label-field">
            <span title="{{cargo?.cargoFeature?.uploadDownload?.origin?.name}}">
              {{cargo?.cargoFeature?.uploadDownload?.origin?.name}}
              {{cargo?.cargoFeature?.uploadDownload?.origin?.country?
              '('+cargo?.cargoFeature?.uploadDownload?.origin?.country?.name+')':''}}
            </span>
          </div>
        </div>
        <!-- Destination -->
        <div class="col-lg-2 col-sm-4">
          <div class="label">
            Destino
          </div>
          <div class="text-bold label-field">
            <span title="{{
                cargo?.cargoFeature?.uploadDownload?.destination?.length
                  ? cargo.cargoFeature.uploadDownload.destination[
                      cargo.cargoFeature.uploadDownload.destination.length - 1
                    ]?.name
                  : ''
              }}">
              {{
              cargo?.cargoFeature?.uploadDownload?.destination?.length
              ? cargo.cargoFeature.uploadDownload.destination[
              cargo.cargoFeature.uploadDownload.destination.length - 1
              ]?.name
              : ''
              }}
              {{
              cargo?.cargoFeature?.uploadDownload?.destination?.length &&
              cargo.cargoFeature.uploadDownload.destination[
              cargo.cargoFeature.uploadDownload.destination.length - 1
              ]?.country
              ? '(' +
              cargo.cargoFeature.uploadDownload.destination[
              cargo.cargoFeature.uploadDownload.destination.length - 1
              ].country?.name +
              ')'
              : ''
              }}
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4">
          <div class="label">
            Valor Transportado
          </div>
          <div class="text-bold label-field">
            <span
              title="{{cargo?.cargoFeature?.cargoMeasure?.amount ? (cargo?.cargoFeature?.cargoMeasure?.amount  | currency:'COP':'code') : '0 COP'}}">
              {{cargo?.cargoFeature?.cargoMeasure?.amount ? (cargo?.cargoFeature?.cargoMeasure?.amount |
              currency:'COP':'code'):'0 COP'}}
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4">
          <div class="label">
            Placa
          </div>
          <div class="text-bold label-field" [ngClass]="{'underline': showVehicleDetail && cargo?.licensePlate}">
            <span title="{{ cargo?.licensePlate }}"
              (click)="cargo?.licensePlate ? getDetailVehicle('openDetail') : null">
              {{ cargo?.licensePlate || '-' }}
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4" *ngIf="cargo?.state === cargoStateEnum.START_SERVICE">
          <div class="label">
            Conductor
          </div>
          <div class="text-bold label-field" [ngClass]="{'underline': showDriverDetail}" *ngIf="cargo?.driver">
            <span title="{{cargo?.driver}}" (click)="getDetailUserByDocument()">
              {{cargo?.driver}}
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4" *ngIf="cargo?.escortedVehicle?.licensePlate">
          <div class="label">
            Vehículo escoltado
          </div>
          <div class="text-bold label-field cursor-pointer">
            <u [matTooltip]="escortedVehicleTooltip" matTooltipPosition="above" matTooltipClass="extended-tooltip">
              {{cargo?.escortedVehicle?.licensePlate}}
            </u>
          </div>
        </div>
      </div>

      <!-- Body Default -->
      <div class="row cargo__body">
        <!-- Rate -->
        <div class="col-lg-2 col-sm-4" *ngIf="hasPermissionFinancier">
          <div class="label">
            Tarifa
          </div>
          <div class="text-bold label-field">
            <span title="{{cargo?.shippingCost?.rate}}">
              {{
              cargo?.shippingCost?.rate !== null && cargo?.shippingCost?.rate !== undefined
              ? (cargo.shippingCost.rate | currency: 'COP':'code')
              : '-'
              }}
            </span>

          </div>
        </div>
        <div class="col-lg-2 col-sm-4" permission-display [module]="permission.cargo?.module"
          [functionality]="permission.cargo?.showRateCargo">
          <div class="label">
            Total de la tarifa
          </div>
          <div class="text-bold label-field">
            <span title="{{cargo?.shippingCost?.rate}}">
              {{
              (cargo?.shippingCost?.totalCost !== null && cargo?.shippingCost?.totalCost !== undefined) &&
              (cargo?.shippingCost?.rate !== null && cargo?.shippingCost?.rate !== undefined)
              ? (cargo.shippingCost.rate + cargoItemService.getTotalAdditionalRateDriver(cargo) | currency:'COP':'code')
              : '-'
              }}
            </span>

          </div>
        </div>
        <!-- Total Cost -->
        <div class="col-lg-2 col-sm-4" *ngIf="hasPermissionFinancier">
          <div class="label">
            Flete
          </div>
          <div class="text-bold label-field">
            {{
            cargo?.shippingCost?.totalCost !== null && cargo?.shippingCost?.totalCost !== undefined
            ? (cargo?.shippingCost?.totalCost | currency: 'COP':'code')
            : '-'
            }}
          </div>
          <div class="text-bold label-field" *ngIf="cargoItemService.getTotalBonuses(cargo)">
            <span class="title-4 text__color color--success"
              title="Bonificaciones: + {{cargoItemService.getTotalBonuses(cargo)}}">
              <span class="text__color color--success">(+)</span>
              <a class="text__color color--success text-underlined" role="button"
                (click)="openShippingCostDetails(0)">{{cargoItemService.getTotalBonuses(cargo) |
                currency:'COP':'code'}}</a>
            </span>
          </div>
          <div class="text-bold label-field" *ngIf="cargoItemService.getTotalDiscounts(cargo)">
            <span class="title-4 text__color color--error"
              title="Descuentos: + {{cargoItemService.getTotalDiscounts(cargo)}}">
              <span class="text__color color--error">(-)</span>
              <a class="text__color color--error text-underlined" role="button"
                (click)="openShippingCostDetails(1)">{{cargoItemService.getTotalDiscounts(cargo) |
                currency:'COP':'code'}}</a>
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4" permission-display [module]="permission.cargo.module"
          [functionality]="permission.cargo.showTotalCostCargo">
          <div class="label">
            Total del flete
          </div>
          <div class="text-bold label-field" *ngIf="cargo?.shippingCost?.paid">
            <span class="text__color color--success" title="Pagada">
              Pagada
            </span>
          </div>
          <div class="text-bold label-field" *ngIf="!cargo?.shippingCost?.paid">
            <span *ngIf="(cargo?.shippingCost?.totalCost + cargoItemService.getTotalAdditionalCostsDriver(cargo))
            ||(cargo?.shippingCost?.totalCost + cargoItemService.getTotalAdditionalCostsDriver(cargo)===0)"
              [title]="cargo?.shippingCost?.totalCost + cargoItemService.getTotalAdditionalCostsDriver(cargo)">
              {{cargo?.shippingCost?.totalCost + cargoItemService.getTotalAdditionalCostsDriver(cargo) |
              currency:'COP':'code'}}
            </span>
          </div>
          <div class="text-bold label-field" *ngIf="cargo?.shippingCost?.paid">
            <span>
              {{cargo?.shippingCost?.totalPaid | currency:'COP':'code'}}
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4" *ngIf="cargo?.state != cargoStateEnum.START_SERVICE">
          <div class="label">
            Peso del servicio
          </div>
          <div class="text-bold label-field">
            <span
              title="{{cargo?.cargoFeature?.cargoMeasure?.totalWeigth ? cargo?.cargoFeature?.cargoMeasure?.totalWeigth : '0'}}">
              {{cargo?.cargoFeature?.cargoMeasure?.totalWeigth ? cargo?.cargoFeature?.cargoMeasure?.totalWeigth : '0'}}
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4" *ngIf="cargo?.additionalCosts?.length">
          <div class="label">
            Servicios adicionales
          </div>
          <div class="text-bold label-field underline">
            <span (click)="showAdditionalServiceCargo()">
              Ver servicios adicionales
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4" *ngIf="cargo?.observation">
          <div class="label">
            Observaciones
          </div>
          <div class="text-bold label-field cursor-pointer">
            <span *ngIf="cargo?.observation"><u matTooltip="{{cargo?.observation}}" matTooltipPosition="above"
                matTooltipClass="extended-tooltip">
                Servicio
              </u></span>
            <span *ngIf="cargo?.observationDriver"> - <u matTooltip="{{cargo?.observationDriver}}"
                matTooltipPosition="above" matTooltipClass="extended-tooltip">
                Conductor
              </u></span>
          </div>
        </div>
        <div class="col-lg-6" *ngIf="cargo?.state != cargoStateEnum.START_SERVICE">
          <div class="label">
            Tipo de mercancía
          </div>
          <div class="text-bold label-field"
            title="{{cargo?.cargoFeature?.productType?.name ? cargo?.cargoFeature?.productType?.name : '-'}}">
            <span>
              {{cargo?.cargoFeature?.productType?.name ? cargo?.cargoFeature?.productType?.name : '-'}}
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4"
          *ngIf="cargo?.state === cargoStateEnum.START_SERVICE  && cargoLastPointLocation?.city">
          <div class="label">
            Ciudad
          </div>
          <div class="text-bold label-field">
            {{cargoLastPointLocation.city}}
          </div>
        </div>
        <div class="col-lg-6" *ngIf="cargo?.state === cargoStateEnum.START_SERVICE  && cargoLastPointLocation?.address">
          <div class="label">
            Dirección
          </div>
          <div class="text-bold label-field">
            {{cargoLastPointLocation?.address}}
          </div>
        </div>
      </div>

      <!-- Body Financier-->
      <div class="row cargo__body cargo__body--financier" *ngIf="hasPermissionFinancier">
        <!-- Rate -->
        <div class="col-lg-2 col-sm-4">
          <span class="title-purple label">
            Anticipo:
          </span>
          <span class="text-bold label text__color color--success" *ngIf="cargo?.shippingCost?.advanceState">
            {{cargoItemService.getTotalValueAdvance(cargo)
            ? 'Pagado ('+ (cargoItemService.getTotalValueAdvance(cargo) | currency:'COP':'code':'0.2') + ')'
            : cargoItemService.getTotalValueAdvance(cargo) | currency:'COP':'code':'0.2'
            }}
          </span>

          <span class="text-bold label-field"
            *ngIf="(cargo?.shippingCost?.valueAdvance || cargo?.shippingCost?.valueAdvance===0) && !cargo?.shippingCost?.advanceState">
            {{cargoItemService.getTotalValueAdvance(cargo) | currency:'COP':'code':'0.2'}}
          </span>
        </div>
        <div class="col-lg-2 col-sm-4 d-flex flex-column">
          <div>
            <span class="title-purple label">
              Saldo:
            </span>
            <span class="text-bold label text__color color--success" *ngIf="cargo?.shippingCost?.balanceState">
              Pagado ({{cargoItemService.getCargoBalancePaid(cargo) | currency:'COP':'code':'0.2'}})
            </span>
            <span class="text-bold label-field" *ngIf="!cargo?.shippingCost?.balanceState">
              {{cargo?.shippingCost ?
              (balance |
              currency:'COP':'code':'0.2')
              : '-'}}
            </span>
          </div>
          <div *ngIf="cargoItemService.getTotalValueBalance(cargo) > 0">
            <span class="title-purple label cursor-pointer" (click)="summaryBalance(cargo)">
              <u>Ver detalle saldo</u>
            </span>
          </div>
        </div>
        <div class="col-lg-2 col-sm-4">
          <span class="title-purple label">
            Reteica:
          </span>
          <span class="text-bold label-field">
            {{
            cargo?.shippingCost?.reteicaValue !== null && cargo?.shippingCost?.reteicaValue !== undefined
            ? (cargo?.shippingCost?.reteicaValue | cargoTaxes:isChargeTaxes | currency:'COP':'code':'0.2')
            : '-'
            }}
          </span>
        </div>
        <div class="col-lg-2 col-sm-4">
          <span class="title-purple label">
            Retefte:
          </span>
          <span class="text-bold label-field">
            {{
            cargo?.shippingCost?.retefteValue !== null && cargo?.shippingCost?.retefteValue !== undefined
            ? (cargo?.shippingCost?.retefteValue | cargoTaxes:isChargeTaxes | currency:'COP':'code':'0.2')
            : '-'
            }}
          </span>
        </div>
        <div class="col-lg-2 col-sm-4" *ngIf="isTeclogiUser">
          <span class="title-purple label">
            Serv. Integrales:
          </span>
          <span class="text-bold label-field">
            {{
            cargo?.shippingCost && utils.isDefined(cargo?.shippingCost?.totalCost)
            ? (cargo?.shippingCost?.integralValue | currency:'COP':'code':'0.2')
            : '-'
            }}
          </span>
        </div>
        <div class="col-lg-2 col-sm-4" *ngIf="cargo?.state === cargoStateEnum.END_SERVICE">
          <span class="title-purple label">
            Pronto pago solicitado:
          </span>
          <span class="text-bold label-field">
            {{ cargo?.fastPayment ? 'Sí' : 'No' }}
          </span>
        </div>
      </div>
      <div class="row cargo__body cargo__body--financier relative"
        *ngIf="getShowAdditionalService() && hasPermissionFinancier">
        <div class="col-lg-4" *ngIf="getValueAdditionalCost('Servicio cargue')">
          <span class="title-purple label">
            Serv. Cargue
          </span>
          <span class="text-bold label-field">
            {{getValueAdditionalCost('Servicio cargue') | currency:'COP':'code':'0.2'}}
          </span>
        </div>
        <div class="col-lg-4" *ngIf="getValueAdditionalCost('Servicio descargue')">
          <span class="title-purple label">
            Serv. Descargue
          </span>
          <span class="text-bold label-field">
            {{getValueAdditionalCost('Servicio descargue') | currency:'COP':'code':'0.2'}}
          </span>
        </div>
        <div class="col-lg-4" *ngIf="getValueAdditionalCost('Stand by')">
          <span class="title-purple label">
            Serv. Stand by
          </span>
          <span class="text-bold label-field">
            {{getValueAdditionalCost('Stand by') | currency:'COP':'code':'0.2'}}
          </span>
        </div>
      </div>

      <div class="row cargo__body cargo__body--financier relative"
        *ngIf="hasPermissionFinancier && typeList == 'paymentTravelExpenses' && !!travelExpense">
        <div class="col-md-4">
          <span class="title-purple label">
            {{travelExpense?.travelExpensesType?.name}}
          </span>
          <span class="text-bold label-field" *ngIf="!travelExpense?.paid">
            {{travelExpense?.totalPaid | currency:'COP':'code':'0.2'}}
          </span>
          <span class="text-bold label text__color color--success" *ngIf="travelExpense?.paid">
            Pagado ({{travelExpense?.totalPaid | currency:'COP':'code':'0.2'}})
          </span>
        </div>
      </div>

      <!-- Message validated documents vehicle -->
      <div class="cargo__alert" *ngIf="showAlertCargo">
        <div class="col-lg-12 alert alert-warning">
          <div *ngIf="isPendingApproveBankAccount()">
            <i class="fas fa-exclamation-triangle"></i>
            {{'Los documentos para pago del vehículo '}}
            <span class="font-weight-bold">{{cargo?.licensePlate}}</span>
            {{' no están validados.'}}
            <small><span class="font-wight-bold underline text-uppercase" (click)="getDetailVehicle('openDetail')">Ir a
                Validar</span></small>
          </div>
          <div *ngIf="isPendingConfirmLicensePlate()">
            <i class="fas fa-exclamation-triangle"></i>
            {{'La placa '}}
            <span class="font-weight-bold">{{cargo?.licensePlate}}</span>
            {{'aún no se ha confirmado para iniciar este servicio.'}}
            <small><span class="font-wight-bold underline text-uppercase"
                (click)=" confirmDriverValidations()">Confirmar
                Placa</span></small>
          </div>
          <div *ngIf="isPendingApproveBalance()">
            <i class="fas fa-exclamation-triangle"></i>
            No se ha aprobado el pago del saldo, se debe confirmar desde la aplicación móvil Teclogi Conductor o los
            encargados.
            <small>
              <span class="font-wight-bold underline text-uppercase" (click)="validateApproveBalance()">
                ¿Quién puede Aprobar?
              </span>
            </small>
          </div>
          <div *ngIf="isPendingApprove()">
            <i class="fas fa-exclamation-triangle"></i>
            No se ha aprobado el servicio
          </div>

          <div *ngIf="isPendingApproveTravelExpense()">
            <i class="fas fa-exclamation-triangle"></i>
            Hay viáticos pendientes de legalización. <span class="underline"
              (click)="showLegalizeTravelExpensesDialog()">Ir a legalizar</span>
          </div>
        </div>
      </div>
    </div>

    <div class="d-table-cell w-100 text-center">
      <figure class="icon-arrow-container" (click)="openDetail(cargo)" *ngIf="!hideOptionsLoad">
        <img src="/assets/svg/icons/icon-arrow-right-short.png" alt="">
      </figure>
      <app-cargo-options class="cargo-options" type="ellipsis" [cargo]="cargo" [typeList]="typeList"
        [isNegotiation]="isNegotiation" (refreshListCargo)="applyFilter.emit()">
      </app-cargo-options>
    </div>
  </div>
</section>

<!-- Menu optionsCargo -->
<mat-menu #optionsCargo="matMenu">
  <button mat-menu-item>
    <mat-icon><i class="fas fa-check-square"></i></mat-icon>
    <span>completada</span>
  </button>
</mat-menu>