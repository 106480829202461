<div class="list-transport-requests" infinite-scroll [infiniteScrollDistance]="paginationList.getScrollDistance()"
    [infiniteScrollThrottle]="paginationList.getThrottle()" [scrollWindow]="false" (scrolled)="onScrollDown()">
    <app-list-filter [options]="optionsFilter" [typeList]="typeList"
        (refreshList)="refreshList($event)"></app-list-filter>
    <!--app-cargo-item-request *ngFor="let transportRequet of paginationList.getList()" [cargoItemList]="transportRequet"
        [item]="transportRequet">
    </app-cargo-item-request-->
    <app-cargo-item *ngFor="let transportRequet of paginationList.getList()" [cargoItemList]="transportRequet"
        [typeList]="typeList" [option]="optionsFilter"
        [filteredCargosTurns]="getFilteredCargo(transportRequet?.consecutive, transportRequet?.cargoFeature?.vehicleType?.name, transportRequet?.cargoFeature?.uploadDownload?.origin?.municipalityCode, transportRequet?.dateLoad)"
        (applyFilter)="refreshList()">
    </app-cargo-item>
</div>