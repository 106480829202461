export class Companies {

  static companies = [
    {
      "businessName": "TECLOGI",
      "commonName": "TECLOGI",
      "nit": "9013312320"
    },
    {
      "businessName": "BULKMATIC DE COLOMBIA S.A.S",
      "commonName": "BULKMATIC",
      "nit": "9009704395"
    },
    {
      "businessName": "INVERTRAICO S.A.S",
      "commonName": "INVERTRAICO",
      "nit": "9011981505"
    },
    {
      "businessName": "JL & RB S.A.S",
      "commonName": "JL & RB",
      "nit": "9007387182"
    },
    {
      "businessName": "GRUPO NUTRESA",
      "commonName": "GRUPO NUTRESA",
      "nit": "9003410860"
    },
    {
      "businessName": "CARGA PÁRAMO S.A.S",
      "commonName": "CARGA PÁRAMO",
      "nit": "9014171480"
    },
    {
      "businessName": "TRANSPORTE LA ESTRELLA SAS",
      "commonName": "TRANSPORTE LA ESTRELLA",
      "nit": "8000130291"
    },
    {
      "businessName": "SEGURIDAD EXTREMA LTDA",
      "commonName": "SEGURIDAD EXTREMA",
      "nit": "9013522401"
    },
    {
      "businessName": "TRANSALUD",
      "commonName": "TRANSALUD",
      "nit": "9012861321"
    }
  ];

  static companiesWithDisabledRegistration = [
    "8000130291"
  ];

  static companiesNIT = {
    LOGGIAPP: "default",
    TECLOGI: "9013312320",
    BULKMATIC: "9009704395",
    INVERTRAICO: "9011981505",
    JL_RB: "9007387182",
    NUTRESA: "9003410860",
    PARAMO: "9014171480",
    LA_ESTRELLA: "8000130291",
    SEGURIDAD_EXTREMA: "9013522401",
    TRANSALUD: "9012861321"
  };


  static companiesAccess(route: string, clientNit: string, saasNit: string): boolean {
    switch (route) {
      case '/analytics/dispatch':
      case '/analytics/vehicleAnalytics':
        //Solo usuarios Teclogi, no sus clientes
        return [this.companiesNIT.TECLOGI].includes(clientNit);
      case '/administration/companies/config':
        //Solo usuarios de las empresas SaaS, no sus clientes
        return this.companies.some(company => company.nit === clientNit);
      case '/analytics/frequentRoutes':
      case '/analytics/stopedCargos':
        //Usuarios y clientes de Teclogi y Bulkmatic
        return [this.companiesNIT.BULKMATIC, this.companiesNIT.TECLOGI].includes(saasNit);
      case '/analytics/analyticsBilling':
      case '/analytics/operationITR':
        //Usuarios Teclogi y Bulkmatic, no sus clientes
        return [this.companiesNIT.BULKMATIC, this.companiesNIT.TECLOGI].includes(clientNit);
      default:
        return true;
    }
  }

  static permissionAccess(permission: string, clientNit: string, saasNit: string): boolean {
    switch (permission) {
      case 'dispatch':
      case 'vehicleAnalytics':
        //Solo usuarios Teclogi, no sus clientes
        return [this.companiesNIT.TECLOGI].includes(clientNit);
      case 'frequentRoutes':
      case 'stopedCargos':
        return [this.companiesNIT.BULKMATIC, this.companiesNIT.TECLOGI].includes(saasNit);
      case 'analyticsBilling':
      case 'operationITR':
        //Usuarios Teclogi y Bulkmatic, no sus clientes
        return [this.companiesNIT.BULKMATIC, this.companiesNIT.TECLOGI].includes(clientNit);
      default:
        return true;
    }
  }

  static modulesAccess(module: string, nit: string): boolean {
    switch (module) {
      default:
        return true;
    }
  }

}
