import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { ParamsCargoList } from 'src/app/core/interfaces/paramsCargoList';

interface TransportRequest {
  title: string;
  typeList: string;
  paramsCargoList: ParamsCargoList;
}

@Component({
  selector: 'app-transport-request-listing-tabs',
  templateUrl: './transport-request-listing-tabs.component.html',
  styleUrls: ['./transport-request-listing-tabs.component.scss']
})
export class TransportRequestListingTabsComponent implements OnInit {

  public listTransportRequests: Array<TransportRequest> = [
    /*
      title: "Asignadas",
      typeList: "assignedRequests",
      paramsCargoList: {
        assignedDriver: true,
        states: ['Accepted', 'Request', 'Created']
      }
    },*/
    {
      title: "Sin asignar",
      typeList: "unassignedRequests",
      paramsCargoList: {
        assignedDriver: false,
        states: ['Request', 'Created']
      }
    },
    /*{
      title: "No publicadas",
      typeList: "",
      paramsCargoList: {

      }
    }*/
  ];

  constructor() { }

  ngOnInit() {
  }

  public onSelectTab($event: MatTabChangeEvent): void {
    console.log($event);
  }

}
