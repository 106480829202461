import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { AuthService } from 'src/app/core/services/authentication.service';
import { Global } from 'src/app/core/resources/global';
import { ManualCreationCargoService } from 'src/app/modules/cargo/manual-creation-cargo/manual-creation-cargo.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReactiveForm } from 'src/app/core/resources/reactive-form';
import { Utils } from 'src/app/core/resources/utils';
import { CargoResources } from '../../../manual-creation-cargo/resources/cargo';
import { Cargo } from 'src/app/core/interfaces/cargo';
import { SnackBarService } from 'src/app/core/services/snackBar.service';
import { CargoDetailService } from '../../cargo-detail.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Patterns } from 'src/app/core/resources/patterns';
import { CompanyNamePipe } from 'src/app/core/pipe/companyName.pipe';
import { PermissionRole } from 'src/app/core/resources/permission-role';
import { Permission } from 'src/app/core/resources/permission';
import { CargoMessages } from 'src/app/core/messages/cargo-messages.enum';
import { ThirdPartyConsignmentService } from '../../../manual-creation-cargo/components/third-party-consignment/third-party-consignment.service';
import { Observable, Subscription } from 'rxjs';
import { OptionsAutocomplete } from 'src/app/core/interfaces/optionsAutocomplete';
import { DateManager } from 'src/app/core/managers/date.manager';
import { CargoStateDict, CargoStateEnum } from 'src/app/core/enums/cargoState.enum';
import { TRIP_TYPES } from 'src/app/core/enums/tripTypes.enum';
import { ServiceType } from 'src/app/core/interfaces/serviceType';
@Component({
  selector: 'app-general-data-cargo',
  templateUrl: './general-data-cargo.component.html',
  styleUrls: ['./general-data-cargo.component.scss'],
  providers: [AuthService, Global, CompanyNamePipe]
})
export class GeneralDataCargoComponent extends ReactiveForm implements OnInit {
  permission = Permission;
  listAddresses: any[] = [];
  listAddressesAsync: Observable<any[]>;
  otherFreightCost: string;
  showOtherFreightCost: boolean = false;
  edit: boolean;
  active: boolean = false;
  dateField = new FormControl("");
  cargoOwnerGroup = new FormGroup({ address: new FormControl('') });
  companyControl = new FormControl("", Validators.required);
  companySub: Subscription;
  branchOfficeSub: Subscription;
  containerSub: Subscription;
  validate: string = "";
  branchOfficeName: string = '';
  branchOfficeCode: string = '';
  companyOptions: OptionsAutocomplete = {
    title: "Operación",
  }
  branchOfficeControl = new FormControl("", Validators.required);
  branchOfficeOptions: OptionsAutocomplete = {
    title: "Propietario del servicio"
  }
  fromNowFilter = DateManager.filter({ from: new Date() });
  @Input() cargo: Cargo;
  @Output() emitToParent: EventEmitter<any> = new EventEmitter();
  @Output() cleanCargo: EventEmitter<any> = new EventEmitter();

  constructor(
    public manualCreationCargoService: ManualCreationCargoService,
    public thirdPartyConsignmentService: ThirdPartyConsignmentService,
    public formBuilder: FormBuilder,
    public utils: Utils,
    private cargoResources: CargoResources,
    private snackBarService: SnackBarService,
    private cargoDetail: CargoDetailService,
    private spinner: NgxSpinnerService,
    private patterns: Patterns,
    public companyNamePipe: CompanyNamePipe,
    private permissionRole: PermissionRole,
  ) {
    super(
      formBuilder,
      utils.clone(cargoResources.cargoMock)
    );
  }

  ngOnInit() {
    this.form.get('container').setValidators(Validators.pattern(this.patterns.CONTAINER_FORMAT_FULL.source));
    this.containerSub = this.form.get('container').valueChanges.subscribe(value => {
      if (value && this.cargo.cargoModel.tripType.name === "Importación" && !this.isEscortedService) {
        this.dateField.setValidators(Validators.required);
        this.dateField.updateValueAndValidity();
      } else {
        this.dateField.clearValidators();
        this.dateField.updateValueAndValidity();
      }
    })
    this.setSubscription();
  }

  setSubscription() {
    this.companySub = this.companyControl.valueChanges.subscribe(value => {
      if (value && value.companyId) this.form.get('idCompany').setValue(value.companyId);
      else this.form.get('idCompany').setValue('');
    })
    this.branchOfficeSub = this.branchOfficeControl.valueChanges.subscribe(value => {
      if (value && value.companyId && value.name && this.form.get('ministry').value) {
        this.form.get('cargoOwner.documentNumber').setValue(value.companyId);
        this.form.get('cargoOwner.name').setValue(value.name);
      } else {
        this.form.get('cargoOwner.documentNumber').setValue('');
        this.form.get('cargoOwner.name').setValue('');
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.cargo && changes.cargo.currentValue) {
      this.updateForm(this.cargo);
      this.toggleEdit(false);
      this.getBranchOffice();
    }
  }

  private getBranchOffice() {
    if (!this.cargo || !this.cargo.cargoOwner || !this.utils.isDefined(this.cargo.cargoOwner.branchOffice) || !this.cargo.cargoOwner.documentNumber) {
      this.branchOfficeName = '';
      this.branchOfficeCode = '';
      return;
    }
    this.branchOfficeCode = `${this.cargo.cargoOwner.branchOffice}`;
    this.thirdPartyConsignmentService.getThirdPartiesAddressByCompany(null, null, this.cargo.cargoOwner.documentNumber).subscribe(
      (thirdParties: any[]) => {
        if (thirdParties && thirdParties.length) {
          const thirdPartySelected = thirdParties.find(thirdParty => thirdParty && thirdParty.address && thirdParty.address.id === this.cargo.cargoOwner.branchOffice);
          this.branchOfficeName = thirdPartySelected ? thirdPartySelected.address.address : '';
        } else this.branchOfficeName = '';
      }, () => {
        this.branchOfficeName = '';
      })
  }
  onSubmit() {
    this.validate = "touched";
    if (this.form.get('ministry').value && (!this.form.get('cargoOwner').value || !this.form.get('cargoOwner').value.name
      || !this.form.get('cargoOwner').value.documentType || !this.form.get('cargoOwner').value.documentNumber || !this.form.get('cargoOwner').value.branchOffice)) {
      this.snackBarService.openSnackBar('No se ha diligenciado el propietario del servicio', undefined, 'alert');
      return
    } else if (!this.form.get('ministry').value) {
      this.form.get('cargoOwner.documentType').setValue('');
      this.form.get('cargoOwner.documentNumber').setValue('');
      this.form.get('cargoOwner.branchOffice').setValue('');
      this.form.get('cargoOwner.name').setValue('');
    }
    const patternContainer = new RegExp(this.patterns.CONTAINER_FORMAT_FULL.source);
    if (!this.form.get('container').value) {
      this.updateGeneralData(this.utils.clone(this.form.value));
    } else if ((patternContainer.test(this.form.get('container').value))) {
      this.active = false;
      if (this.dateField.valid) this.updateGeneralData(this.utils.clone(this.form.value));
      else this.snackBarService.openSnackBar('No hay una fecha válida de expiración del contenedor', undefined, "alert");
    } else {
      this.active = true;
      this.snackBarService.openSnackBar('Por favor ingresar un numero de contenedor válido', undefined, 'alert')
    }
    this.dateField.markAsTouched();
  }

  updateGeneralData(newCargo: Cargo) {

    const data = { id: this.cargo.id, state: this.cargo.state };

    if (!!newCargo.container) {
      data['container'] = newCargo.container;
      const tripTypesAllowed = [TRIP_TYPES.IMPORT, TRIP_TYPES.NATIONAL, TRIP_TYPES.URBAN];
      if (!tripTypesAllowed.includes(this.cargo.cargoModel.tripType.name) || !this.dateField.value) data['containerExpirationDate'] = null;
      else data['containerExpirationDate'] = DateManager.dateToString(this.dateField.value, 'DD/MM/YYYY');
    }
    else {
      data['container'] = null;
      data['containerExpirationDate'] = null;
    }

    if (!!newCargo.seal) data['seal'] = newCargo.seal;
    else data['seal'] = null;

    if (!!newCargo.numberDocumentSender)
      data['numberDocumentSender'] = newCargo.numberDocumentSender;

    data['ministry'] = newCargo.ministry;

    if (newCargo.ministry && newCargo.cargoOwner && newCargo.cargoOwner.documentNumber && newCargo.cargoOwner.documentType
      && newCargo.cargoOwner.branchOffice && newCargo.cargoOwner.branchOffice) {
      data['cargoOwner'] = {
        documentType: newCargo.cargoOwner.documentType,
        documentNumber: newCargo.cargoOwner.documentNumber,
        branchOffice: newCargo.cargoOwner.branchOffice,
        name: newCargo.cargoOwner.name,
      }
    }
    else data['cargoOwner'] = null;

    const originalMinistry = this.cargo.ministry;
    this.spinner.show();
    this.cargoDetail.completeUpdateRequest(data, this.cargo).subscribe(
      (success) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATED);
        if (originalMinistry !== data['ministry']) this.cleanCargo.emit({ ministry: data['ministry'] });
        this.emitToParent.emit(this.cargo.consecutive);
      },
      (error) => {
        this.spinner.hide();
        this.snackBarService.openSnackBar(CargoMessages.CARGO_UPDATE_ERROR_DEFAULT, undefined, 'error');
      });
  }

  toggleEdit(value: boolean) {
    const cargoDisable: Cargo = this.utils.clone(this.cargoResources.cargoMock);
    this.edit = value;
    const editableFields = [CargoStateEnum.REQUEST].includes(this.cargo.requestState) || [CargoStateEnum.REQUEST].includes(this.cargo.state)
      ? { seal: '', container: '', containerExpirationDate: '', numberDocumentSender: '', ministry: '', idCompany: '', cargoOwner: '' }
      : { seal: '', container: '', containerExpirationDate: '', numberDocumentSender: '' };
    if (this.edit) {
      this.enabledFieldsForm(editableFields, this.form);
      if (this.form.get('containerExpirationDate').value)
        this.dateField.setValue(DateManager.stringToDate(this.form.get('containerExpirationDate').value, 'DD/MM/YYYY'));

    } else {
      this.disabledFieldsForm(cargoDisable, this.form);
      this.active = false;
    }
    this.companyOptions['initialNit'] = this.cargo.idCompany;
    this.companyOptions = { ...this.companyOptions };
    if (this.cargo.ministry && this.cargo.cargoOwner && this.cargo.cargoOwner.documentNumber) {
      this.branchOfficeOptions['initialNit'] = this.cargo.cargoOwner.documentNumber;
      this.branchOfficeOptions = { ...this.branchOfficeOptions };
    }

  }

  get canEdit(): boolean {
    return this.permissionRole.hasPermission(this.permission.cargo.module, this.permission.cargo.editCargo)
  }

  onSelectAddress($event) {
    if ($event && $event.information && $event.address) {
      let addressSelected = $event;
      let data = {
        documentType: addressSelected.information.documentTypeId,
        documentNumber: addressSelected.information.document,
        branchOffice: addressSelected.address.id,
        name: addressSelected.information.name,
      };
      this.form.get('cargoOwner').patchValue(data);
    }
  }

  public initialAddressCargoOwner(value): string {
    if (value && value.address && value.address.address) {
      return value.address.address;
    } else {
      return '';
    }
  }

  get cargoState(): string {
    if (this.cargo)
      return CargoStateDict[this.cargo.state ? this.cargo.state : this.cargo.requestState ? this.cargo.requestState : ''];
    return '';
  }

  get isEscortedService(): boolean {
    const serviceType: ServiceType = this.utils.getNestedValue(this.cargo, 'cargoModel.serviceType');
    return serviceType && serviceType.id === 'escortServices';
  }

  ngOnDestroy() {
    if (this.containerSub) this.containerSub.unsubscribe();
    if (this.companySub) this.companySub.unsubscribe();
    if (this.branchOfficeSub) this.branchOfficeSub.unsubscribe();
  }

}
