import { Directive, ElementRef, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Patterns } from '../resources/patterns';
import { Utils } from '../resources/utils';

@Directive({
    selector: 'input[currencyMask]',
})
export class CurrencyMaskDirective implements OnInit {

    constructor(
        public ngControl: NgControl,
        private elementRef: ElementRef,
        public utils: Utils
    ) { }

    ngOnInit(): void {
        if (this.elementRef && this.elementRef.nativeElement && this.elementRef.nativeElement.value) {
            this.onInputChange(this.elementRef.nativeElement.value);
        }
    }

    @HostListener('ngModelChange', ['$event'])
    onModelChange(event): void {
        this.onInputChange(event);
    }

    @HostListener('keydown.backspace', ['$event'])
    keydownBackspace(event): void {
        this.onInputChange(event.target.value);
    }

    onInputChange(event): void {
        if (!this.utils.isEmpty(event)) {
            let value = this.utils.numberMaskToNumber(event);
            let numericValue = this.utils.parseNumber(value);

            // Escribir el valor numérico en el modelo (sin formato)
            this.ngControl.valueAccessor.writeValue(numericValue);

            // Aplicar formato de miles en la visualización
            this.updateView(numericValue);
        }
    }

    @HostListener('input') onChange(): void {
        let inputValue = this.elementRef.nativeElement.value;

        if (!this.utils.isEmpty(inputValue)) {
            // Remover todo lo que no sea número o punto decimal
            let newVal = inputValue.replace(/[^0-9.]/g, '');

            // Separar la parte entera y la decimal
            let [integerPart, decimalPart] = newVal.split('.');

            // Eliminar cualquier separador de miles en la parte entera
            integerPart = integerPart.replace(/,/g, '');

            // Aplicar formato de miles solo a la parte entera
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            // Reconstruir el valor formateado (visual)
            let formattedValue = decimalPart !== undefined ? `${integerPart}.${decimalPart}` : integerPart;

            // Actualizar la visualización del input con el valor formateado
            this.elementRef.nativeElement.value = formattedValue;

            // Convertir a número antes de actualizar el modelo
            let numericValue = this.utils.parseNumber(formattedValue);

            // Actualizar el modelo con el valor numérico
            this.ngControl.control.setValue(numericValue, {
                emitEvent: false,
                emitModelToViewChange: false,
                emitViewToModelChange: false
            });
        }
    }

    // Actualizar la visualización del input sin cambiar el valor del modelo
    private updateView(value: number): void {
        let formattedValue = this.utils.formatNumberWithCommas(value);
        this.elementRef.nativeElement.value = formattedValue;
    }
}